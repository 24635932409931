/**
 * @file
 * Styles for product categories and single product display.
*/

@import "variables";

//
// Node Display
//

body.node-type-apparel-display,
body.node-type-flag-display,
body.node-type-product-display {
  #block-system-main {
    margin-left: 20px;
  }
}

body.page-node {
  #content {
    .node-product-display, .node-flag-display, .node-apparel-display {
      width: 100%;
    }
  }
}

.node-product-display,
.node-apparel-display,
.node-flag-display {
  &.view-mode-full {
    .field-field-bulk-pricing {
      margin-top: 1em;
    }
  }
}

//
// Product Display (Used for both taxonomy list AND node)
//

#content {
  .node-product-display, .node-flag-display, .node-apparel-display { // <-- ADD DISPLAYS/Product Types
    background: #eee;
    position: relative;
    border: 1px solid #eee;
    display: inline-block;
    
    .content {
      @include clearfix();
    }
  
    .group-1 { // Image, Price
      float: left;
      width: 60%;
      margin-left: 0;
      margin-right: -100%;
      overflow: visible;
      word-wrap: break-word;
      position: relative;
      /*@media only screen and (max-width: 990px) {
        @include zen-grid-item(6,1);
        padding: 0;
      }
      @media only screen and (max-width: 600px) {
        @include zen-grid-item(12,1);
      }*/
      
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      
      .commerce-product-field-field-image, .field-name-field-images, .commerce-product-field-field-images { // Image
        line-height: 0;
        text-align: center;
        background: white;
        padding: 20px;
        margin: 10px;
        
        img {
          margin-bottom: 0;
        }
      
        // Cloudzoom
        #wrap {
          float: none !important;
          display: inline-block;
          max-width: 100%;
        }      
      
      }
      
      // Multi Image
      .commerce-product-field-field-images {
        padding: 20px;    
        
        // More than 1 thumbnail
        &.has-thumbs {
          padding: 20px 20px 120px;
        }   
      }
      
      // Multi Image Thumbs
      .cloud-zoom-gallery-thumbs {
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 1000;
        > a {
          display: none;
          padding: 5px;
          background: white;
          border: 3px solid #ccc;
          margin: 5px 0 5px 5px;
          @include transition(
            border-color 0.1s ease-in-out
          );
          
          &:hover {
            border-color: $blue-dark;
          }
        }
        &.show-thumbs {
          > a {
            display: inline-block;
          }
        }
      }

      .field-commerce-price { // Price
        background: $blue-dark;
        font-family: $secondary-typeface;
        color: white;
        text-align: center;
        font-size: 2.5em;
        line-height: 1em;
        padding: 10px;
        .field-label {
          font-weight: normal;
          display: inline-block;
          float: none;
        }
        .field-items {
          display: inline-block;
          float: none;
        }
      }
    }
  
    .group-2 { // Title, Body, Quantity, Add to Cart Button, Checkout Button
      width: 100%;
      padding: 10px 20px 0px 60%;
      margin-right: -100%;
      margin-left: 10px;
      overflow: visible;
      word-wrap: break-word;
      float: left;
      //position: relative;
      /*@media only screen and (max-width: 990px) {
        @include zen-grid-item(6,7);
        padding: 0;
      }
      @media only screen and (max-width: 600px) {
        @include zen-grid-item(12,1);
        clear: both;
      }*/
      
      @media only screen and (max-width: 1024px) {
        padding-left: 0;
        clear: both;
        margin-right: 0;
      }
      
      .field.clearfix:after {
        //content: none;
      }
      
      .field-name-body { // Body
        
      }
      .field-name-field-category { // Category
      }
      
      .field-field-apparel-size {
      }
      
      .field-name-title { // Title
        h2 {
          margin: 0;
        }      
      }
      
      .commerce-product-extra-field-title { // Title on Apparel Products
        font-family: $secondary-typeface;
        font-size: 2.85em;
        line-height: 1.1em;
        
        .commerce-product-title-label { display: none; }
      
      }
      
      .field-name-body { // Body
        
      }
      .field-name-field-category { // Category
      }
      
      .field-field-apparel-size {
      }
    
      .group-group2-inner {
        //padding: 20px 20px 80px;
        padding: 20px 0 10px 0;
	//text-align: center;
        //position: relative;
        
        .field-name-field-product { // Quantity & Add to Cart Button
          font-size: 2em;
          font-family: $secondary-typeface;
          color: $red-dark;
          
          .form-item-quantity {
            display: inline-block;
            margin: 0;// 0 15px 0 0;
            margin-right: 5px;
            padding: 0;
            
            label {
              font-weight: normal;
              display: none;
            }
            
            .commerce-quantity-plusminus-link { 
              display: none;
            }
            .form-text { // Form input
              background: $red-dark;
              //color: white;
              text-align: center;
              height: 60px;
              width: 75px;
              
              padding-top: 5px;
              
              background: white;
              @include text-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
              @include box-shadow(inset 0px 0px 4px rgba(0, 0, 0, 0.6));
              
            }
            
          }
          .form-submit {
            padding: 20px 10px;
            display: inline-block;
            height: 60px;
            font-size: .75em;
            float: none;
            margin-right: 0;
            padding: 0 30px;
          }        
        }
      }

      .commerce-add-to-cart {
	//text-align: center;
	
	.form-submit {
	  margin-top: 10px;
	}
      }

      .attribute-widgets {
        color: $grey;
        font-family: $primary-typeface;
        font-size: $font-size-normal;
        label {
          font-family: $secondary-typeface;
        }

	text-align: left;
      } 
    }
    .group-3 {
      clear: right;
      width: 100%;
      padding-left: 320px;
      background: #eee;
      overflow: visible;

      @media only screen and (min-width: 768px) and (max-width: 959px), (max-width: 627px) {
        width: 100%;
        padding-left: 0;
        padding: 0 10px;
      }
      @include clearfix();
      float: left;
      .field-name-field-product { // Add to Cart Form
        font-size: 1em;        
         
        .form-item-quantity {
          display: inline-block;
          margin: 0 5px 0 0;
          position: absolute;
          right: 159px;
          padding: 0;

          label {
            font-weight: normal;
            display: inline;
            font-size: 2em;
            color: $red-dark;
            font-family: $secondary-typeface;
          }            
          .commerce-quantity-plusminus-link { 
            display: none;
          }
          .form-text { // Form input
            background: $red-dark;
            text-align: center;
            height: 56px;
            width: 75px;
            font-size: 28px;
            background: white;
            @include text-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
            @include box-shadow(inset 0px 0px 4px rgba(0, 0, 0, 0.6));
          }            
        }
          .form-submit {
            padding: 20px 10px;
            display: inline-block;
            font-size: 21px;
            float: right;
            margin: 0 10px 10px 0;
                        
          }
        }
        .field-name-checkout-button { // Checkout Button
          
        }

    }
    
    .size-chart {
      a.size-chart-link {
        cursor: pointer;
      }
      .size-chart-content {
        display: none;
      }
    }
  }
}
img.chart-img {
  max-width: none !important;
}

  
  // 
  // Flag Display Block
  //
    /*.node-flag-display { 
  
    .group-1 { // Image, Price
      float: left;
      width: 300px;
      margin-left: 0;
      margin-right: -100%;
      overflow: visible;
      word-wrap: break-word;
      @media only screen and (max-width: 990px) {
        @include zen-grid-item(6,1);
        padding: 0;
      }
      @media only screen and (max-width: 600px) {
        @include zen-grid-item(12,1);
      }
      .commerce-product-field-field-image { // Image
        line-height: 0;
        text-align: center;
        img {
          margin-bottom: 0;
        }
      }
      .field-commerce-price { // Price
        background: $blue-dark;
        font-family: $secondary-typeface;
        color: white;
        text-align: center;
        font-size: 2.5em;
        line-height: 1em;
        padding: 10px;
        .field-label {
          font-weight: normal;
          display: inline-block;
          float: none;
        }
        .field-items {
          display: inline-block;
          float: none;
        }
      }
    }
  
    .group-2 { // Title, Body
      width: 100%;
      padding-left: 300px;
      margin-right: -100%;
      overflow: visible;
      word-wrap: break-word;
      @media only screen and (max-width: 990px) {
        @include zen-grid-item(6,7);
        padding: 0;
      }
      @media only screen and (max-width: 600px) {
        @include zen-grid-item(12,1);
        clear: both;
      }
      .group-group2-inner {
        background: #eee;
        padding: 20px 20px 60px;
        position: relative;
        .field-name-title { // Title
          h2 {
            margin: 0;
          }
        }
        .commerce-product-title {
          font-weight:600;
        }
        .commerce-product-title-label {
          display:none;
        }        
      }
    }

    .group-3 {
      clear: both;
      width: 100%;
      .field-name-field-product { // Add to Cart Form
        font-size: 1em;        
         
        .form-item-quantity {
          display: inline-block;
          margin: 0 5px 0 0;
          position: absolute;
          right: 100px;

          label {
            font-weight: normal;
            display: inline;
          }            
          .commerce-quantity-plusminus-link { 
            display: none;
          }
          .form-text { // Form input
            background: $red-dark;
            text-align: center;
            height: 60px;
            width: 75px;
            background: white;
            @include text-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
            @include box-shadow(inset 0px 0px 4px rgba(0, 0, 0, 0.6));
          }            
        }
          .form-submit {
            padding: 20px 10px;
            display: inline-block;
            font-size: 2em;
            float: none;
            margin-right: 0;            
          }
        }
        .field-name-checkout-button { // Checkout Button
          
        }

    }

  }*/

// 
// Related Products Block
//

#block-views-cbc22f17d4aab7fe60540ad457726afa {
  clear: left;
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  
  .block-header { // Block Title
    h2 {
      text-align: center;
      color: $orange;
      padding: 5px 0 1px;
      background: #eee;
      margin-bottom: 15px;
      margin-top: 0;
    }
  }
  
  .view-related-category {
    .view-content > div {
      background: white;
      border: 1px solid $grey-light;
      width: 23%;
      margin: 0 1% 30px;
      float: left;
      @include transition(
        all 0.2s ease-in-out
      );

      @media only screen and (max-width: 1235px) {
        width: 31%;
      }
      @media only screen and (max-width: 870px) {
        width: 48%;
      }
      @media only screen and (max-width: 767px) {
        width: 31%;
      }
      @media only screen and (max-width: 560px) {
        width: 48%;
      }
      @media only screen and (max-width: 380px) {
        width: 100%;
      }
      
      &:hover {
        @include box-shadow(0 0 9px rgba(0,0,0,0.2));      
      }

    }
    
    .views-field-field-images { // Image
      text-align: center;
      padding: 10px 0;
      display: table-cell;
      width: 500px;
      vertical-align: middle;
      height: 140px;
    }
    
    .views-field-name { // Theme Title
      background: #ddd;
      text-align: center;
      line-height: 1em;
      padding: 10px;
      .field-content {
        display: table-cell;
        vertical-align: middle;
        width: 500px;
        height: 36px;
      }
      a {
        color: $red-dark;
      }
    }
    
  }
  
}
  

//
// Field Made In Styles
//

.field-name-field-made-in {
  margin-bottom: 15px; 
}


//
// Flag Product Configurator Overrides (For Responsive)
//

.node-flag-display .commerce-add-to-cart .form-item.form-item-attributes-field-flag-size, .node-flag-display .commerce-add-to-cart .form-item.form-item-attributes-field-material {
  max-width: 30em;
  width: 100%;
}
